<template>
    <div>
        <div class="container-fluid ">
            <div class="container my-5 py-5">
                <div class="d-flex flex-column align-items-center">
                    <img class="col-4 mb-5" :src="vector.url" alt="" />
                    <h1 class="col-8 fw-bold text-center text-darkblue my-4">
                        XERO BEAUTIFUL CLOUD ACCOUNTING SOFTWARE
                    </h1>
                    <div class="col-6 text-center mb-5">
                        Try Xero free for your business and sample every feature with unlimited users — you’ll see how Xero makes running your business a breeze.
                    </div>
                    <div class="col-8">
                        <div class="row g-5">
                            <div v-for="(feature, index) in features" :key="index" class="col-6">
                                <h5 class="text-blue mb-2">{{ feature.title }}</h5>
                                <span>{{ feature.desc }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
li {
    list-style: "—   ";
}
</style>
<script>
export default {
    data() {
        return {
            vector: {
                url: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fdiscount.svg?alt=media&token=bb59c77b-df80-478a-a906-985786f1b5da"
            },
            features: [
                {
                    title: "INTELLIGENT BANKING",
                    desc: "Xero automatically imports all your bank, credit card and Paypal transactions."
                },
                {
                    title: "EASY INVOICING",
                    desc: "Create professional recurring invoices and receive updates when they’re opened."
                },
                {
                    title: "PAY BILLS",
                    desc: "Manage your cashflow by scheduling payments and batch paying suppliers."
                },
                {
                    title: "FINANCIAL REPORTS",
                    desc: "With one-click you can create reports and budgets all using your real-time business data."
                },
                {
                    title: "GO MOBILE",
                    desc: "The Xero mobile app works with iPhone and iPad & Android phones and tablets."
                },
                {
                    title: "XERO EXPENSE CLAIM",
                    desc: "Capture an image of the receipt with your mobile device and send it straight into Xero."
                },
                {
                    title: "MORE THAN 700 ADD-ONS APPLICATIONS",
                    desc: "From Payroll, HR, time tracking, expenses stock control to point of sale- and more all integrate with Xero."
                },
            ]
        }
    }
}
</script>